import React from 'react'

import FourOFour from 'gatsby-theme-landmarks-core/src/pages/404'
import logo from '../images/site/Scala-Center-horizontal.png'

export default props => {
  return (
    <FourOFour
      {...props}
      logoSrc={logo}
      logoAlt="Scala Frunzisului"
      metaTitle="Scala Frunzisului | Pagina inexistenta"
      metaDescription="Pagina nu a fost gasita | Page not found"
    />
  )
}
